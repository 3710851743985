@import url('https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap');

.App {
  text-align: center;
  margin-top: 50px;
}

form {
  margin-top: 20px;
}

input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: solid 1px white;
  margin-top: 1em;
}

p {
  font-size: 20px;
}

*{
  background-color: black;
  color: white;
}

h1{
  font-family: 'Saira Stencil One', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
}


.email-field{
  margin-top: 2em;
}